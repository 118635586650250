<template>
    <header>
        <div class="border-bottom border-bottom border-1 py-2">
            <h1 class="my-3 headline text-center">{{title}}</h1>
        </div>
    </header>
</template>

<script>
export default {
    name: 'PageHeader',
    data() {
        return {
            title: 'News Articles'
        }
    }
}
</script>