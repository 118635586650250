<template>
    <footer class="bg-light pt-5">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-4">
                    <h5 class="fw-bold">LOGO</h5>
                    <p>Lorem ipsum dolo consectetur the adipiscing Lobortis mattis neque adipiscing nisl.</p>
                    <div class="d-flex align-items-center gap-2">
                        <a href="#" class="btn btn-secondary rounded-circle d-flex align-items-center justify-content-center">
                            <i class="bi bi-twitter"></i>
                        </a>
                        <a href="#" class="btn btn-secondary rounded-circle d-flex align-items-center justify-content-center">
                            <i class="bi bi-instagram"></i>
                        </a>
                        <a href="#" class="btn btn-secondary rounded-circle d-flex align-items-center justify-content-center">
                            <i class="bi bi-facebook"></i>
                        </a>
                        <button class="btn btn-secondary">Subscribe</button>
                    </div>
                </div>
                <div class="col-md-2 mb-4">
                    <h5 class="fw-bold">Headlines</h5>
                    <ul class="list-unstyled">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Events</a></li>
                        <li><a href="#">About</a></li>
                    </ul>
                </div>
                <div class="col-md-2 mb-4">
                    <h5 class="fw-bold">Categories</h5>
                    <ul class="list-unstyled">
                        <li><a href="#">Formula 1</a></li>
                    </ul>
                </div>
                <div class="col-md-4 mb-4">
                    <h5 class="fw-bold">Get Latest Updates</h5>
                    <form class="d-flex gap-2 mt-3">
                        <input type="email" class="form-control" placeholder="Email address here">
                        <button type="submit" class="btn btn-secondary"><i class="bi bi-caret-right-fill"></i></button>
                    </form>
                    <p class="mt-2">Lorem ipsum dolo consectetur the adipiscing Lobortis mattis neque adipiscing nisl.</p>
                </div>
            </div>
        </div>
        <div class="text-center py-3 mt-3 border-top">
            <div class="container">
                <div class="row">
                    <div class="col text-start">
                        <div>
                            <p class="mb-0">All rights reserved</p>
                        </div>
                    </div>
                    <div class="col text-end">
                        <div>
                            <ul class="list-inline d-inline-flex gap-4">
                                <li class="list-inline-item"><a href="#">Privacy & Terms</a></li>
                                <li class="list-inline-item"><a href="#">FAQ</a></li>
                                <li class="list-inline-item"><a href="#">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'PageFooter',
    props: {
        title: String
    }
}
</script>