<template>
    <nav class="navbar navbar-expand-lg bg-white mb-5 py-4 border-bottom border-1">
        <div class="container">
            <a class="navbar-brand fw-bold" href="#">LOGO</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mb-2 mb-lg-0 border-start border-1 ps-3 gap-3">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/events" class="nav-link">Events</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/about" class="nav-link">About</router-link>
                    </li>
                </ul>
                <div class="d-flex me-auto ms-auto align-items-center">
                    <div class="my-4 my-md-0">
                        <DateAndTime />
                    </div>
                </div>
                <div class="d-flex">
                    <SearchBox />
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import DateAndTime from "@/components/DateAndTime.vue";
import SearchBox from "@/components/SearchBox.vue";

export default {
    name: 'PageNavigation',
    components: {SearchBox, DateAndTime},
}
</script>