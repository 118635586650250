<template>
    <div class="input-group input-group-sm d-flex align-items-center">
        <input type="text" class="form-control rounded-pill position-relative z-0" placeholder="Search News" aria-label="Search News" aria-describedby="button-addon2">
        <button class="btn border-0 rounded-pill position-absolute end-0 z-1" type="button" id="button-addon2"><i class="bi bi-search"></i></button>
    </div>
</template>

<script>
export default {
    name: 'SearchBox',
    props: {
        title: String
    }
}
</script>