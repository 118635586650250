import { createApp } from 'vue'
import App from './views/App.vue'
import Home from './views/Home.vue'
import Events from './views/Events.vue'
import About from './views/About.vue'

import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/events',
        component: Events
    },
    {
        path: '/about',
        component: About
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: 'active',
})

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/app.scss'

const app = createApp(App);
app.use(router);
app.mount('#app')