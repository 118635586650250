<template>
    <div class="container">
        <p>
            This is an about page.
        </p>
    </div>
</template>

<script>

export default {
    name: 'PageAbout',
    props: {
        title: String
    }
}
</script>
