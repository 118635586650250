<template>
    <div class="pb-3 mb-5">
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-3 article-sm mb-3" v-for="news in newsData" :key="news.id">
                    <div class="article-title mb-1 fw-bold">
                      <u><a :href="news.link" target="_blank">{{ news.title ? news.title.replace(/^"|"$/g, '') : 'No Title Available' }}</a></u>
                    </div>
                    <div class="article-description text-justify">
                        <p>{{ stripHTML(news.summarized_content) || 'No Description' }}</p>
                    </div>
                    <div class="d-flex gap-2 my-2 align-items-center article-category">
                        <div class="fw-bold">{{news.channel_name}}</div><div class="fw-thin">{{formatDate(news.pub_date)}}</div>
                    </div>
                    <div v-if="news.children && news.children.length > 0">
                        <div v-for="child in news.children" :key="child.id" class="article-category">
                            <a :href="child.link"  target="_blank">
                                <u><span class="fw-bold">{{child.channel_name}}</span></u>
                            </a> -
                            <span class="fw-thin">{{formatDate(child.pub_date)}}</span>
                        </div>
                    </div>
                    <div v-else>&nbsp;</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// get verticalId from package.json
import { verticalId } from '../../package.json';

export default {
  data() {
    return {
      newsData: null
    };
  },
  methods: {
    fetchData() {
      console.log('verticalId:', verticalId);
      axios
        .get(`https://db-proxy-dev.frontpagesystems.com/get-all-vertical-content-by-vertical-id?verticalId=${verticalId}` , {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        })
        .then((response) => {
            console.log('API Response:', response);
            this.newsData = response.data;
            console.log('newsData:', this.newsData);
        })
        .catch((error) => {
            console.error('API Error:', error);
        });
    },
    stripHTML(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    },
    formatDate(pubDate) {
      if (!pubDate || !pubDate.seconds) return '';

      const date = new Date(pubDate.seconds * 1000);
      return isNaN(date.getTime()) ? '' : date.toLocaleDateString();
    }
  },
  mounted() {
    this.fetchData();
  }
};

</script>