<template>
    <!-- <PageHeader/> -->
    <ArticleSection/>
    <PageFooter/>
</template>

<script>
//import PageHeader from '@/components/PageHeader.vue'
import ArticleSection from "@/components/ArticleSection.vue";
import PageFooter from "@/components/PageFooter.vue";
export default {
    name: 'PageHome',
    components: {
        //PageHeader,
        ArticleSection,
        PageFooter
    },
    props: {
        title: String
    }
}
</script>
