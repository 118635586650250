<template>
<div>
    <PageHeader />
    <PageNavigation />
    <router-view></router-view>
</div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import PageNavigation from '@/components/PageNavigation.vue'
export default {
    name: 'App',
    components: {
        PageNavigation,
        PageHeader
    },
}
</script>

