<template>
    <span>{{ new Date().toLocaleString('default', { month: 'long' }) }}&nbsp;</span>
    <span>{{ new Date().getDate() }},&nbsp;</span>
    <span>{{ new Date().getFullYear() }},&nbsp;</span>
    <span>{{ new Date().toLocaleTimeString().split(':').slice(0, 2).join(':').split(' ')[0] + ' ' + new Date().toLocaleTimeString().split(' ')[1]}}</span>
</template>

<script>
export default {
    name: 'DateAndTime',
    props: {
        title: String
    }
}
</script>